<script>
import AuthLayout from '@app/layouts/AuthLayout.vue';
import LoginForm from '@app/components/auth/LoginForm.vue';
import AskPasswordForm from '@app/components/auth/AskPasswordForm.vue';

export default {
  name: 'login',
  components: { AuthLayout, LoginForm, AskPasswordForm },
  head: {
    title: 'Connexion',
  },
  data() {
    return {
      isLogin: true,
    };
  },
};
</script>

<template>
  <AuthLayout class="page">
    <template #menu>
      <router-link to="/inscription">
        Inscription
      </router-link>
    </template>

    <template #title>
      Bon retour parmi nous
    </template>
    <template #description>
      <strong class="has-text-link">Vivez de votre savoir</strong> et créez la meilleure expérience de formation pour vos apprenants.
    </template>

    <template #figure>
      <BaseLazyImg
        class="page_img mx-auto"
        :src="require('@app/assets/img/illus/content.png')"
        :src-low="require('@app/assets/img/illus/content.png?lqip')"
        :src-webp="require('@app/assets/img/illus/content.png?webp')"
      />
    </template>

    <LoginForm v-if="isLogin" @switch="isLogin = false" />
    <AskPasswordForm v-else @switch="isLogin = true" />
  </AuthLayout>
</template>

<style lang="scss" scoped>
.page {
  &_img {
    max-width: 360px;
  }
}
</style>
